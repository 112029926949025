<template>
  <v-container>

    <NavBar :step="5"></NavBar>

    <Titulo></Titulo>

    <br>
    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">

      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">
      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="5">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col :class="vista">

                  <br>
                  <br>

                  <h3 class="text-center labelIngresar">
                    Ingresa tus datos
                  </h3>

                  <h4 class="text-center labelSubingresar">
                    Son necesarios para completar la póliza del seguro
                  </h4>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.name" :rules="nameRules" label="Nombre" required
                    maxlength="51" filled solo @change="change()"></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.surname" :rules="surnameRules" label="Apellido"
                    maxlength="51" required filled solo @change="change()"></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.email" :rules="emailRules" label="Email" required
                    maxlength="51" filled solo @change="change()"></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.phoneNumber" :rules="phoneRules" prefix="+56"
                    :counter="9" maxlength="9" label="Teléfono" required filled solo @change="change()"></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model.lazy="newRut" :rules="rutRules" maxlength="13"
                    label="Ingresa tu RUT ( sin punto y con guión)" required filled solo @input="changeRut"
                    @change="change()"></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.serialNumber" :rules="serialNumberRules"
                    maxlength="10" label="Ingresa el número de Documento / Serie" required filled solo @change="change()">
                  </v-text-field>

                  <br>

                  <v-select v-if="userData.salud == 'Isapre'" class="labelsDatos" :items="isapreTypes"
                    v-model="userData.typeIsapre" label="Tipo Isapre" :rules="typeIsapreRules" return-object required
                    filled solo></v-select>

                  <br v-if="userData.salud == 'Isapre'">

                  <v-text-field class="labelsDatos" v-model="userData.address" :rules="addressRules" maxlength="51"
                    label="Ingresa tu dirección" required filled solo @change="change()"></v-text-field>

                  <br>

                  <v-select class="labelsDatos" :items="regions" v-model="userData.region" :rules="regionRules"
                    :loading="loadingRegions" v-on:change="getCommunes()" item-text="name"
                    label="Selecciona tu región de residencia" required return-object filled solo
                    @change="change()"></v-select>

                  <br>

                  <v-select class="labelsDatos" :items="communes" v-model="userData.commune" :rules="communeRules"
                    :loading="loadingCommunes" :disabled="loadingCommunes || userData.region === null" item-text="name"
                    label="Selecciona tu comuna de residencia" required return-object filled solo @change="change()">
                  </v-select>

                  <br>
                  <br>

                  <div class="button-container">
                    <v-btn
                      style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 100%; height: 120%;"
                      @click="validate()">
                      Continuar
                    </v-btn>

                    <br>
                    <br>

                    <v-btn text @click="back()"> Atras </v-btn>

                  </div>

                </v-col>

              </div>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>

import CountryService from "@/services/country.service.js";
import Titulo from "../components/Titulo.vue"
import RutUtils from "@/utils/rut.utils.js";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
import NavBar from "@/components/NavBar.vue";
import ShieldService from "../services/shield.service";

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      address: null,
      email: null,
      phoneNumber: null,
      typeIsapre: null,
      salud: null,
    },
    isapreTypes: ["Banmédica", "Chuquicamata", "Colmena", "Consalud", "Cruz Blanca", "Cruz del Norte", "Nueva Masvida", "Fundación", "Fusat", "Río Blanco", "San Lorenzo Ltda.", "Vida Tres"],
    screen: window.screen.width,
    stepperColor: '#FFB600',
    stepId: null,
    TipoPlan: null,
    e1: 5,
    month: null,
    day: null,
    year: null,
    months: [],
    days: [],
    yars: [],
    valid: false,
    addressRules: [
      (v) => !!v || "Debes ingresar tu dirección",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    typeIsapreRules: [(v) => !!v || "Debes ingresar tu tipo de ISAPRE",],
    regionRules: [(v) => !!v || "Debes ingresar tu región",],
    communeRules: [(v) => !!v || "Debes ingresar tu comuna",],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    serialNumberRules: [
      (v) => !!v || "El número de serie es requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres"
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    phoneRules: [
      (v) => !!v || "Número telefónico requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    regions: [],
    communes: [],
    newRut: "",

  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.stepId = localStorage.getItem('stepId')
    this.userData.name = localStorage.getItem('name')
    this.userData.surname = localStorage.getItem('surname')
    this.userData.email = localStorage.getItem('email')
    this.userData.phoneNumber = localStorage.getItem('phoneNumber')
    this.userData.rut = localStorage.getItem('rut')
    this.newRut = localStorage.getItem('rut')
    this.userData.serialNumber = localStorage.getItem('serialNumber')
    this.userData.address = localStorage.getItem('address')
    this.userData.salud = localStorage.getItem('salud')
    if (this.screen > 800) {
      this.vista = 'vista2'
      this.TipoPlan = 'PlanEscritorio'
    } else {
      this.vista = 'vista'
      this.TipoPlan = 'PlanMovil'
    }
    this.getRegions();
    this.getMonths();
    this.getYears();
  },
  methods: {
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    SaveDates(dates) {
      this.$refs.menu.save(dates)
    },
    validate() {
      if (this.$refs.form.validate()) {
        localStorage.setItem("name", this.userData.name);
        localStorage.setItem("surname", this.userData.surname);
        localStorage.setItem("email", this.userData.email);
        localStorage.setItem("phoneNumber", this.userData.phoneNumber);
        localStorage.setItem("rut", this.userData.rut);
        localStorage.setItem("serialNumber", this.userData.serialNumber);
        localStorage.setItem("address", this.userData.address);
        localStorage.setItem("typeIsapre", this.userData.typeIsapre);
        localStorage.setItem("commune", this.userData.commune.id);
        localStorage.setItem("region", this.userData.region.id);

        this.$router.push({ path: '/resumen' })

      }
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.month) {
        this.loadingDays = true;
        this.day = null;
        this.days = await dateService.getDays(
          this.month.name
        );
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    back() {
      if (this.userData.typePlan == "Individual") {
        this.$router.push({ path: '/planes' })
      } else {
        this.$router.push({ path: '/datos-beneficiarios' })
      }
    },
    async change() {

      var values;

      try {
        values = [
          this.userData.name,
          this.userData.surname,
          this.userData.serialNumber,
          this.userData.rut,
          this.userData.region != null ? this.userData.region.name : '',
          this.userData.commune != null ? this.userData.commune.name : '',
          this.userData.address,
          this.userData.email,
          this.userData.phoneNumber,
        ]
        var carro = await ShieldService.createStep(values, 4, 0, this.stepId)
        this.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart Fail");
      }

    }
  },
};
</script>

<style lang="scss">
.labelIngresar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  margin-top: -5%;
}

.labelSubingresar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelsDatos {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-date {
  align-items: center;
  display: flex;
  margin-left: 0rem;
}
</style>
<template>
  <v-container>

    <NavBar :step="4"></NavBar>

    <Titulo></Titulo>

    <br v-if="screen < 800">
    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>
                  <br>

                  <h3 class="text-center labelTitulo">Ingresa los datos de tu familia</h3>

                  <h4 class="text-center labelSubTitulo">
                    Como elegiste un plan familiar, puedes agregar a tu pareja e
                    hijos en el mismo plan
                  </h4>

                  <br>
                  <br>

                  <div v-for="(benef, index) in beneficiaries" :key="index">

                    <v-expansion-panels :value="benef.panel" class="labelPanel">

                      <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">

                        <v-expansion-panel-header style="height: 48px;">

                          <label>{{ benef.tipo }}</label>

                          <v-icon v-if="!checkImage(benef)" color="red">mdi-alert-circle</v-icon>

                          <v-img
                            v-if="!benef.name == false && !benef.surname == false && !benef.rut == false && !benef.date == false"
                            contain :src="ticket" :rules="imageRule" @load="allImagesPresent = checkAllImagesPresent()"
                            max-width="18px" max-height="18px" />

                        </v-expansion-panel-header>

                        <v-expansion-panel-content>


                          <div class="form-box">

                            <br>

                            <h4 class="text-center labelEtiqueta">
                              Nombre
                            </h4>

                            <v-text-field class="labelsEntrada" v-model="benef.name" :rules="nameRules" label="Nombre"
                              @change="addLocalStorage()" maxlength="51" style="margin: auto;" required filled
                              solo></v-text-field>

                            <br>
                            <br>

                            <h4 class="text-center labelEtiqueta">
                              Apellido
                            </h4>

                            <v-text-field class="labelsEntrada" v-model="benef.surname" :rules="surnameRules"
                              maxlength="51" @change="addLocalStorage()" label="Apellido" required filled solo
                              style="margin: auto;">
                            </v-text-field>

                            <br>
                            <br>

                            <h4 class="text-center labelEtiqueta">
                              RUT
                            </h4>

                            <v-text-field class="labelsEntrada" v-model.lazy="benef.newRut" :rules="rutRules"
                              @change="addLocalStorage()" label="(Sin puntos y con guión)" required filled solo
                              style="margin: auto;" @input="changeRut(index)"></v-text-field>

                            <br>
                            <br>

                            <h4 class="text-center labelEtiquetaFecha">
                              Fecha de Nacimiento
                            </h4>

                            <div>
                              <v-row>
                                <v-col>
                                  <v-select class="labelDate" :items="months" v-model="benef.month"
                                    :loading="loadingMonths" @change="addLocalStorage()" v-on:change="getDays(index)"
                                    item-text="name" label="Mes" return-object required filled solo>
                                  </v-select>
                                </v-col>

                                <v-col>
                                  <v-select class="labelDate" :items="benef.days" v-model="benef.day"
                                    :loading="loadingDays" :disabled="loadingDays || benef.month === null"
                                    item-text="name" label="Día" return-object required filled solo
                                    @change="setDate(index)">
                                  </v-select>
                                </v-col>

                                <v-col>
                                  <v-select class="labelDate" value="2022" :items="years" v-model="benef.year"
                                    item-text="name" label="Año" return-object required filled solo
                                    @change="setDate(index)">
                                  </v-select>
                                </v-col>
                              </v-row>
                            </div>

                            <br>

                            <div class="button-container">
                              <v-btn class="botonGuardar" style="background-color: rgba(0, 44, 119, 1); color: white;"
                                @click="save(index)">
                                Guardar
                              </v-btn>
                            </div>

                            <br>

                          </div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <br>
                    </v-expansion-panels>

                  </div>

                  <br>

                  <div class="button-container">
                    <v-btn class="botonAgregar" style="background-color: #FFB600; color: white;"
                      @click="addBeneficiary()">
                      AGREGAR OTRO FAMILIAR
                    </v-btn>
                    <br>
                    <br>
                    <br>
                  </div>

                  <div class="button-container">
                    <v-btn class="botonContinuar" style="background-color: rgba(0, 44, 119, 1); color: white;"
                      @click="validate()">
                      Continuar
                    </v-btn>

                    <br>
                    <br>

                    <v-btn text @click="back()">
                      Atrás
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import RutUtils from "@/utils/rut.utils.js";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
import NavBar from "@/components/NavBar.vue";

const moment = require('moment');
import swal from 'sweetalert';

import ticket from "@/assets/images/ticket.png"
import Titulo from "../components/Titulo.vue"

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    lengthBenef: null,
    beneficiaries: [],
    allImagesPresent: false,
    ticket,
    loadingDays: false,
    screen: window.screen.width,
    stepperColor: '#00968F',
    stepId: null,
    options: [
      { text: "Hija", value: "opcion1" },
      { text: "Hijo", value: "opcion2" },
      { text: "Cónyuge", value: "opcion3" },
    ],
    selectedOption: "",
    fieldsToShow: [],
    showTextField: false,
    e1: 4,
    month: null,
    day: null,
    year: null,
    months: [],
    days: [],
    yars: [],
    valid: false,
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    newRut: "",
  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.stepId = localStorage.getItem("stepId");
    this.lengthBenef = localStorage.getItem("cantBenef");

    this.getBeneficiaries();

    if (this.screen > 800) {
      this.vista = "vista2";
    } else {
      this.vista = "vista";
    }
    this.getMonths();
    this.getYears();
  },
  methods: {
    save(index) {
      if (this.beneficiaries[index].name && this.beneficiaries[index].surname && this.beneficiaries[index].surname && this.beneficiaries[index].day && this.beneficiaries[index].month && this.beneficiaries[index].year) {
        if (this.beneficiaries[index].panel == 0) {
          this.beneficiaries[index].panel = 1
        } else if (this.beneficiaries[index].panel == 1) {
          this.beneficiaries[index].panel = 0
        }
      } else {
        swal({
          title: "Atención!",
          text: "Debe ingresar todos los campos requeridos",
          icon: "info",
          button: "De acuerdo",
        });
      }
    },
    addLocalStorage() {
      var names = ''
      var surnames = ''
      var ruts = ''
      var days = ''
      var months = ''
      var years = ''
      this.beneficiaries.forEach(benef => {
        names = benef.name ? names + benef.name + ';' : names + '' + ';';
        surnames = benef.surname ? surnames + benef.surname + ';' : surnames + '' + ';';
        ruts = benef.rut ? ruts + benef.rut + ';' : ruts + '' + ';';
        days = benef.day ? days + benef.day.id + ';' : days + null + ';';
        months = benef.month ? months + benef.month.number + ';' : months + null + ';';
        years = benef.year ? years + benef.year.id + ';' : years + null + ';';
      });
      localStorage.setItem('benefNames', names)
      localStorage.setItem('benefSurnames', surnames)
      localStorage.setItem('benefRuts', ruts)
      localStorage.setItem('benefDays', days)
      localStorage.setItem('benefMonths', months)
      localStorage.setItem('benefYears', years)
    },
    getBeneficiaries() {
      var contador = 0;
      var olds = localStorage.getItem('benefOlds') != null ? localStorage.getItem('benefOlds').split(';') : null;
      var types = localStorage.getItem('benefTypes') != null ? localStorage.getItem('benefTypes').split(';') : null;
      var names = localStorage.getItem('benefNames') != null ? localStorage.getItem('benefNames').split(';') : null;
      var surnames = localStorage.getItem('benefSurnames') != null ? localStorage.getItem('benefSurnames').split(';') : null;
      var ruts = localStorage.getItem('benefRuts') != null ? localStorage.getItem('benefRuts').split(';') : null;
      // var days = localStorage.getItem('benefDays') != null ? localStorage.getItem('benefDays').split(';') : null;
      // var months = localStorage.getItem('benefMonths') != null ? localStorage.getItem('benefMonths').split(';') : null;
      // var years = localStorage.getItem('benefYears') != null ? localStorage.getItem('benefYears').split(';') : null;
      while (this.lengthBenef != contador) {
        var person = {
          tipo: types != null ? types[contador] : '',
          edad: olds != null ? olds[contador] : '',
          name: names != null ? names[contador] : '',
          surname: surnames != null ? surnames[contador] : '',
          rut: ruts != null ? ruts[contador] : '',
          // year: years != null ? years[contador] : '',
          // month: months != null ? months[contador] : '',
          // day: days != null ? days[contador] : '',
          date: null,
          newRut: ruts != null ? ruts[contador] : '',
          panel: 0,
        }
        this.beneficiaries.push(person)
        contador++;
      }
    },
    addBeneficiary() {
      this.$router.push({ path: '/beneficiarios' })
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays(index) {
      if (this.beneficiaries[index].month) {
        this.loadingDays = true;
        this.beneficiaries[index].day = null;
        this.beneficiaries[index].days = await dateService.getDays(this.beneficiaries[index].month.name);
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    validate() {
      if (this.$refs.form.validate() && this.allImagesPresent) {
        this.$router.push({ path: '/datos-contratante' })
      }
    },
    checkAllImagesPresent() {
      return this.beneficiaries.every(benef => benef.name && benef.surname && benef.rut && benef.date);
    },
    checkImage(benef) {
      return !!(benef.name && benef.surname && benef.rut && benef.date);
    },
    changeRut(index) {
      this.beneficiaries[index].rut = RutUtils.cleanRut(this.beneficiaries[index].newRut);
      this.beneficiaries[index].newRut = RutUtils.formatRut(this.beneficiaries[index].newRut);
      this.beneficiaries[index].rut = this.beneficiaries[index].newRut;

      this.addLocalStorage();
    },
    back() {
      this.$router.push({ path: '/dps' })
    },
    setDate(index) {
      const fechaActual = new moment(new Date());
      if (this.beneficiaries[index].month != null && this.beneficiaries[index].day != null && this.beneficiaries[index].year != null) {
        var date = '' + this.beneficiaries[index].month.number + '-' + this.beneficiaries[index].day.id + '-' + this.beneficiaries[index].year.name;
        var dateNacimiento = moment(new Date(date));
        var timesDiff = fechaActual.diff(dateNacimiento, 'years');
        console.log(this.beneficiaries[index].edad)
        console.log(timesDiff)
        if (timesDiff == this.beneficiaries[index].edad) {
          this.beneficiaries[index].date = '' + this.beneficiaries[index].day.id + '-' + this.beneficiaries[index].month.number + '-' + this.beneficiaries[index].year.name;
        } else {
          this.beneficiaries[index].year = null
          swal({
            title: "Atención!",
            text: "La fecha de nacimiento no coincide con la edad de beneficiario.",
            icon: "info",
            button: "De acuerdo",
          });
        }
      }
      this.addLocalStorage();
    }

  },
};
</script>

<style lang="scss">
.labelTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  margin-top: -5%;
}

.labelSubTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelPanel {
  margin-bottom: 5%;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  margin: auto;
  max-width: 98%;
  margin-top: 20px;
}

.labelsEntrada {
  box-sizing: border-box;
  height: 57px;
  max-width: 85%;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelDate {
  max-width: 135px;
  height: 57px;
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.botonAgregar {
  border-radius: 12px;
  width: 214px;
  height: 39px;
  font-size: 13px;
}

.labelEtiqueta {
  font-family: Helvetica;
  font-style: normal;
  width: 147px;
  height: 25px;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #5a5a5a;
  letter-spacing: -0.01em;
}

.labelEtiquetaFecha {
  font-family: Helvetica;
  font-style: normal;
  width: 147px;
  height: 25px;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #5a5a5a;
  letter-spacing: -0.01em;
  margin-left: 25%;
}

.botonContinuar {
  border-radius: 12px;
  width: 310px;
  height: 100%;
}

.botonGuardar {
  border-radius: 12px;
  width: 250px;
  height: 100%;
}

.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-data {
  max-width: 20%;
  align-items: center;
  // display: flex;

  justify-content: center;

  .v-select {
    max-width: 120px;
  }
}

.col {
  max-width: 89%;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

.form-box {
  box-sizing: border-box;
  background: #f3f3f3;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  border-radius: 6px;
  margin: auto;
}

.v-input .v-label {
  font-size: 12px;
  color: #8e8e8e;
}

// Escritorio
html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel.v-expansion-panel--active.v-item--active div.v-expansion-panel-content div.v-expansion-panel-content__wrap div.form-box div div.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 1px;
}

// Movil
html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel.v-expansion-panel--active.v-item--active div.v-expansion-panel-content div.v-expansion-panel-content__wrap div.form-box div div.row {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 1px;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel.v-expansion-panel--active.v-item--active div.v-expansion-panel-content div.v-expansion-panel-content__wrap div.form-box div div.row div.col {
  max-width: 68%;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.labelPanel.v-item-group.theme--light.v-expansion-panels div.v-expansion-panel button.v-expansion-panel-header i.v-icon.notranslate.mdi.mdi-alert-circle.theme--light.red--text {
  justify-content: end;
}
</style>

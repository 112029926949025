<template>
  <div>

    <br>

    <v-row justify="center">
      <v-dialog v-model="dialogFamiliar" width="430px">
        <v-card>
          <v-card-text tyle="text-align: justify;">
            <div>
              <v-btn icon @click="dialogFamiliar = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>
              <div style="background: #002C77; border-radius: 9px; text-align: center;">
                <div style="padding: 2%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                        color: white; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y protecciones
                    del </label>
                  <label style="font-family: Helvetica;font-style: normal;  font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                        color: white; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                        font-size: 13px; line-height: 18px; letter-spacing: 0.02em; color: white; flex: none; order: 1; flex-grow: 0; text-transform: uppercase; ">
                      {{ info[0][indexInfo] }}</label> del Seguro Oncológico de SeguroFácil.
                  </label>
                </div>

              </div>


              <br>
              <br>
              <div style="padding-left: 5%; ">
                <label class=" encabezado"> • Hasta {{ info[1][indexInfo] }} Reembolso Gastos médicos tratamiento
                  Oncológico familiar.</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%; margin-bottom: 10px;">
                <label class="texto">
                  En caso de que te diagnostiquen a ti o algún integrante de tu familia que esté contigo
                  en el seguro algún tipo de cáncer,
                  podrán iniciar el tratamiento en Centro Médico donde <label class="textoAzul">reembolsaremos
                    hasta
                    el {{ info[1][indexInfo] }} de los gastos</label> del tratamiento por hasta <label
                    class="textoAzul">UF
                    {{ info[2][indexInfo] }} por cada asegurado </label>(más de 1.000 millones de pesos).
                  <br> </label>
              </div>

              <div style="text-align: center;">
                <label class="texto"> Si quieres revisar más en detalle los <br> gastos que cubre </label><a
                  :href="document" target="_blank"> <br>
                  <div style="border: solid 0.75px; margin-left: 32%; margin-right: 32%; border-radius: 5px;  ">haz clic
                    aquí</div>
                </a>
              </div>

              <br>

              <div style="padding-left: 5%;">
                <label class="encabezado"> • Indemnización {{ info[3][indexInfo] }} UF Libre disposición ante
                  diagnóstico positivo.</label>
              </div>



              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">En caso de que te diagnostiquen a ti o algún integrante de tu familia que esté
                  contigo
                  en el seguro algún tipo de cáncer, podrás solicitar a la compañía
                  <label class="textoAzul">{{ info[1][indexInfo] }}</label> de indemnización para que lo usen en lo que
                  quieran, como alojamientos y traslados para tu tratamiento o comprarse lo que necesiten.
                  <label class="textoAzul">
                    Sin preguntas</label>.</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">Esto pueden solicitarlo con la confirmación de <label class="textoAzul">UC
                    CHRISTUS</label> de
                  contratación y hasta de un diagnóstico positivo de cáncer.</label>
              </div>
              <br>
              <br>

              <div style=" padding-left: 5%;">
                <label class=" encabezado"> • Agrega a integrantes de tu familia sin cambiar el precio del
                  seguro.</label>
              </div>


              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">El plan {{ info[0][indexInfo] }} permite que <br> agregues a tu <label
                    class="textoAzul">cónyuge/pareja/conviviente </label>
                  y a <label class="textoAzul">todos </label>tus y sus <label class="textoAzul">hijos e hijas
                  </label>(hasta
                  24 años de edad) por el <label class="textoAzul">mismo valor del plan</label>, y cada uno con la
                  protección de UF 30.000 de tratamiento y con las UF {{ info[5][indexInfo] }} de indemnización ante
                  diagnostico positivo. </label>
              </div>
              <br>
              <div style="padding-left: 5%; ">
                <label class=" encabezado"> • Protección desde el día que contratas</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 5%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul">día que
                    contratas</label>.
                  Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>


            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialogFamiliar = false">
              Aceptar
            </v-btn>
            <br>
            <br>
            <br>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogIndividual" width="430px">
        <v-card>
          <v-card-text style="text-align: justify;">
            <div>
              <v-btn icon @click="dialogIndividual = false" style="float: right;">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <br>
              <br>
              <div style="background: #002C77; border-radius: 9px; text-align: center;">
                <div style="padding: 2%;">
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                      color: white; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y protecciones
                    del </label>
                  <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 13px; line-height: 18px; letter-spacing: 0.02em;
                      color: white; flex: none; order: 1; flex-grow: 0;">Plan<label
                      style="font-family: Helvetica; font-style: normal; font-weight: 700; 
                      font-size: 13px; line-height: 18px; letter-spacing: 0.02em; color: white; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">
                      {{ info[0][indexInfo] }}</label> del Seguro <br> Oncológico de SeguroFácil.
                  </label>
                </div>

              </div>


              <br>
              <div style="padding-left: 5%; ">
                <label class="encabezado"> • Hasta {{ info[1][indexInfo] }} Reembolso Gastos médicos tratamiento
                  Oncológico individual</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 8%; margin-bottom: 10px;">
                <label class="texto">En caso de que te diagnostiquen algún tipo de cáncer, podrás iniciar el tratamiento
                  en
                  Centro Médico donde <label class="textoAzul">reembolsaremos</label> el <label class="textoAzul">{{
                    info[1][indexInfo] }} de los gastos </label>
                  del tratamiento por hasta<label class="textoAzul"> UF {{ info[2][indexInfo] }}</label>
                  (más de 1.000 millones de pesos). <br></label>
              </div>
              <div style="text-align: center;">
                <label class="texto">
                  Si quieres revisar más en detalle los <br> gastos que cubre<a :href="document" target="_blank"> <br>
                    <div style="border: solid 0.75px; margin-left: 32%; margin-right: 32%; border-radius: 5px;  ">haz clic
                      aquí</div>
                  </a>
                </label>


              </div>

              <br>
              <div style="padding-left: 5%; ">
                <label class="encabezado"> • Indemnización {{ info[3][indexInfo] }} UF Libre disposición ante
                  diagnóstico positivo.</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 8%;">
                <label class="texto">En caso de que te diagnostiquen algún tipo de cáncer, podrás solicitar a la compañía
                  <label class="textoAzul">{{ info[3][indexInfo] }} UF</label> de indemnización para que lo uses en lo que
                  quieras, como alojamientos y traslados para tu tratamiento o comprarte lo que necesites.
                  <label class="textoAzul">
                    Sin preguntas</label>.</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 8%;">
                <label class="texto">Esto puedes solicitarlo con la confirmación de <label class="textoAzul">UC
                    CHRISTUS</label> de un diagnóstico positivo de cáncer.</label>
              </div>

              <br>
              <div style="padding-left: 5%; ">
                <label class="encabezado"> • Protección desde el día que contratas</label>
              </div>

              <br>
              <div style="padding-left: 5%; padding-right: 8%;">
                <label class="texto">La protección de este seguro comienza desde el <label class="textoAzul">día que
                    contratas</label>. Olvídate de carencias o semanas para que comience a protegerte. </label>
              </div>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialogIndividual = false">
              Aceptar
            </v-btn>
            <br>
            <br>
            <br>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>

    <div v-if="loadingPlans == false">

      <div class="planInidivual">

        <br>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planInidivualName">{{ planData[0].name }}</label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planIndividualPrecio">{{
          formatPrice(planData[0].values[0].priceCLP) }}* <label class="planIndividualSubPrecio">/ UF {{
    planData[0].values[0].price }} mensual</label></label>

        <br>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planIndividualDetalle">*Precio basado en la edad del
          contratante.</label>

        <br v-if="verMasIndividual == false">

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label v-if="verMasIndividual == false" class="planIndividualSubDetalle">Protege tu
          futuro y tu
          tranquilidad financiera</label>

        <br>

        <v-btn class="elegirPlan" style="background-color: #FFB600;" @click="next(planData[0], 0)">
          <label style="color: white;">Elegir plan</label>
        </v-btn>

        <br>
        <br>

        <div v-if="verMasIndividual == true" class="text-center">

          <label class="planIndividualProtegete">Protégete con el 100% del tratamiento ante cualquier
            diagnóstico
            de
            cáncer.</label>

          <p></p>
          <p class="planIndividualCaracteristicas">Características</p>
          <p></p>

          <li class="planIndividualBeneficios">{{ planData[0].coverage[0].description }}</li>
          <p></p>
          <li class="planIndividualBeneficios">UF {{ ufFormat(planData[0].coverage[1].amount) }}
            {{ planData[0].coverage[1].description }}</li>
          <p></p>
          <li class="planIndividualBeneficios">UF {{ ufFormat(planData[0].coverage[2].amount) }}
            {{ planData[0].coverage[2].description }}</li>

          <br>
          <br>

          <label class="planIndividualVerDetalle" @click="InfoIndividual()">Ver detalle</label>
          
          <br>
          <br>

        </div>

      </div>

      <v-expansion-panels>
        <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
          <v-expansion-panel-header class="planIndividualVerMas" style="margin-top: -2%; color: white;"
            @click="VerMasIndividual()">
            <template v-slot:actions>
              <label style="flex-direction: column; align-items: center; margin-left: -90%"
                v-if="verMasIndividual == false">Ver más</label>
              <v-icon v-if="verMasIndividual == false" style="color: white; margin-top: -5%;">
                mdi-menu-down
              </v-icon>
              <label style="flex-direction: column; align-items: center; margin-left: -55%"
                v-if="verMasIndividual == true">Ver menos</label>
              <v-icon v-if="verMasIndividual == true" style="color: white; margin-top: -5%;">
                mdi-menu-up
              </v-icon>
            </template>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

      <br>
      <br>

      <div class="planFamiliar">

        <div class="planFamiliarRecomendado">

          <label class="planFamiliarConveniente">El Más conveniente</label>

        </div>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class=" text-center planFamiliarName">{{ planData[1].name
        }}</label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planFamiliarPrecio">{{
          formatPrice(planData[1].values[0].priceCLP) }}*
          <label class="planFamiliarSubPrecio">/ UF {{ planData[1].values[0].price }} mensual</label></label>

        <br>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label class="planFamiliarDetalle">*Precio basado en la edad del
          contratante.</label>

        <br v-if="verMasFamiliar == false">

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label v-if="verMasFamiliar == false" class="planFamiliarSubDetalle">¡Asegura
          hasta 7 familiares sin costo adicional!</label>

        <br>

        <v-btn class="elegirPlan" style="background-color: #FFB600;" @click="next(planData[1], 1)">
          <label style="color: white;">Elegir plan</label>
        </v-btn>

        <br>
        <br>

        <div v-if="verMasFamiliar == true" class="text-center">

          <label class="planFamiliarProtegete">Protégete con el 100% del tratamiento ante cualquier diagnóstico de cáncer
          </label>

          <p></p>
          <p class="planFamiliarCaracteristicas">Características</p>
          <p></p>

          <li class="planFamiliarBeneficios">{{ planData[0].coverage[0].description }}</li>
          <p></p>
          <li class="planFamiliarBeneficios">UF {{ ufFormat(planData[1].coverage[1].amount) }}
            {{ planData[0].coverage[1].description }}</li>
          <p></p>
          <li class="planFamiliarBeneficios">UF {{ ufFormat(planData[1].coverage[2].amount) }}
            {{ planData[0].coverage[2].description }}</li>
          <p></p>
          <li class="planFamiliarBeneficios">Agrega a tu cónyuge, pareja, conviviente y a todos tus hijos por un único
            precio.</li>

          <br>

          <label class="planFamiliarVerDetalle" @click="InfoFamiliar()">Ver detalle</label>

          <br>
          <br>

        </div>
      </div>

      <v-expansion-panels>
        <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
          <v-expansion-panel-header class="planFamiliarVerMas" style="margin-top: -2%; color: white;"
            @click="VerMasFamiliar()">
            <template v-slot:actions>
              <label style="flex-direction: column; align-items: center; margin-left: -90%"  v-if="verMasFamiliar == false">Ver más</label>
              <v-icon v-if="verMasFamiliar == false" style="color: white; margin-top: -5%;">
                mdi-menu-down
              </v-icon>
              <label style="flex-direction: column; align-items: center; margin-left: -55%" v-if="verMasFamiliar == true">Ver menos</label>
              <v-icon v-if="verMasFamiliar == true" style="color: white; margin-top: -5%;">
                mdi-menu-up
              </v-icon>
            </template>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>

    </div>

  </div>
</template>

<script>
import ShieldService from "@/services/shield.service.js";

export default {
  name: "Plan",
  props: ["planData", "index", "userData", "loadingPlans"],
  data() {
    return {
      stepperColor: '#FFB600',
      verMasIndividual: false,
      verMasFamiliar: false,
      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      formatterUF: new Intl.NumberFormat("es-CL"),
      stepId: null,
      e1: 3,
      info: [['Individual ISAPRE', 'Familiar ISAPRE', 'Individual FONASA', 'Familiar FONASA'], ['100%', '100%', '100%', '100%'], ['30.000', '30.000', '30.000', '30.000'], ['50', '50', '50', '50'], ['0', '24', '0', '24'], ['0', '50', '0', '50']],
      indexInfo: '0',
      dialogIndividual: false,
      dialogFamiliar: false,
      document: null,
      couponRules: [
        (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
      ],
    }
  },
  async mounted() {

    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.stepId = localStorage.getItem('stepId')


  },
  methods: {
    ufFormat(price) {
      return this.formatterUF.format(price);
    },
    VerMasIndividual() {
      if (this.verMasIndividual == false) {
        this.verMasIndividual = true
      } else {
        this.verMasIndividual = false
      }
    },
    VerMasFamiliar() {
      if (this.verMasFamiliar == false) {
        this.verMasFamiliar = true
      } else {
        this.verMasFamiliar = false
      }
    },
    async SendCouponID() {
      this.planData = await ShieldService.getPlans(
        this.userData.couponId
      );
      if (this.planData.length === 0) {
        this.$alert("No se encontraron planes", "Información", "info")
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    InfoFamiliar() {
      if (this.planData[1].name == 'Plan Familiar ISAPRE') {
        this.indexInfo = 1
        this.document = '/documents/ISAPRE_v2.pdf'
      }
      else if (this.planData[1].name == 'Plan Familiar FONASA') {
        this.indexInfo = 3
        this.document = '/documents/FONASA_v2.pdf'
      }
      this.dialogFamiliar = true
    },
    InfoIndividual() {
      if (this.planData[0].name == 'Plan Individual ISAPRE') {
        this.indexInfo = 0
        this.document = '/documents/ISAPRE_v2.pdf'
      }
      else if (this.planData[0].name == 'Plan Individual FONASA') {
        this.indexInfo = 2
        this.document = '/documents/FONASA_v2.pdf'
      }
      this.dialogIndividual = true
    },
    async next(plan, item) {
      localStorage.setItem("planId", plan._id);
      localStorage.setItem("firstPlanId", plan._id);
      localStorage.setItem("couponId", this.userData.couponId);

      var values;
      try {
        values = {
          plan: item == 0 ? this.planData[0] : this.planData[1]
        }
        var carro = await ShieldService.createStep(values, 2, 0, this.stepId)
        this.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart Fail");
      }


      if (item == 1) {
        localStorage.setItem("typePlan", "Familiar");
        this.userData.typePlan = "Familiar"
        this.$router.push({ path: '/beneficiarios' })
      } else if (item == 0) {
        localStorage.setItem("typePlan", "Individual");
        this.userData.typePlan = "Individual"
        this.$router.push({ path: '/dps' })
      }
    },
  },
};
</script>

<style scoped>
.labelElige {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
}

.planInidivual {
  width: 100%;
  background: linear-gradient(219deg, #F5F6FF 0%, #DADCFB 50%), #D8D8D8;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;
}

.planInidivualDetalle {
  background: linear-gradient(192.38deg, #C4C6FF 3.57%, #F5F6FF 107.06%), #D8D8D8;
  transform: matrix(1, 0, 0, 1, 0, 0);
  width: 327px;
}

.planInidivualName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #002C77;
}

.planIndividualPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #002C77;
}

.planIndividualSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(53, 53, 53, 0.65);
}

.planIndividualDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(58, 58, 58, 0.85);
}

.planIndividualSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #002C77;
}

.planIndividualVerMas {
  background: #0077A0;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
}

.planIndividualProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
}

.planIndividualCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planIndividualBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  text-indent: -15px;
  text-align: justify;
  margin: auto;
}

.planIndividualVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #002C77;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.elegirPlan {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 39px;
  top: calc(50% - 39px/2 + 100px);
  background: #FFB600;
  border: 2px solid #FFB600;
  border-radius: 8px;
  margin-left: 23%;
  margin-top: 4%;
}

.planFamiliar {
  width: 100%;
  background: linear-gradient(0deg, #0077A0 0%, #0077A0 100%), #D8D8D8;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;
}

.planFamiliarRecomendado {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  gap: 8px;
  width: 136px;
  height: 22px;
  border-radius: 27px;
}

.planFamiliarConveniente {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #585858;
  flex: none;
  order: 0;
  background: #FFB600;
  padding: 5px 25px;
  gap: 8px;
  width: 175px;
  height: 22px;
  border-radius: 27px;
  margin-top: -15%;
  margin-left: 45%;
}

.planFamiliarName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
}

.planFamiliarPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.planFamiliarSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.65);
}

.planFamiliarDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
}

.planFamiliarSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
}

.planFamiliarVerMas {
  background: #FFB600;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
  margin: auto;
}

.planFamiliarProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.planFamiliarCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planFamiliarBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  text-indent: -15px;
  text-align: justify;
  margin: auto;
}

.planFamiliarVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 44, 119, 1);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #002C77;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  align-items: center;
  display: flex;
}

#app>div>main>div>div>div.v-stepper.v-sheet.theme--light.elevation-0>div.v-stepper__items>div>div>div:nth-child(11)>div:nth-child(2)>div {
  border-radius: 10px 10px 10px 10px;
}

.v-expansion-panel::before {
  content: none;
}
</style>
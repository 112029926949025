<template>
  <v-container>

    <NavBar :step="3" ></NavBar>

    <Titulo></Titulo>

    <br v-if="screen < 800">
    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Planes
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 12"
            step="5">Datos</v-stepper-step>
        </div>
      </v-stepper-header>

      <v-stepper-items>

        <v-stepper-content step="6">
          <v-form ref="form2" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>

                  <br v-if="screen > 800">
                  <br v-if="screen > 800">

                  <h3 class="text-center labelSuperior">
                    Antes de continuar, debemos conocer el estado de tu salud y el de tu familia. Recuerda que responder
                    con información erronea podria afectar tu cobertura
                  </h3>

                  <br>

                  <h3 class="text-center labelTitulo">
                    {{ userData.name }}, por favor responde la Declaración Personal de Salud
                  </h3>

                  <br>

                  <label class="labelQuestion1">
                    Pregunta 1
                  </label>

                  <br>
                  <br>

                  <label class="labelTexto">
                    ¿Tú, {{ userData.name }} y/o alguno de tus asegurados adicionales <b> están en estudio o conocimiento
                      y/o han sido
                      diagnosticados y/o sometidos a tratamientos médicos </b> por alguna de las siguientes
                    causas?
                  </label>

                  <br><br>

                  <label class="labelBeneficioss">

                    • Cáncer o enfermedad neoplásica de cualquier naturaleza (incluidos leucemias y linfomas) <br>
                    • Virus VIH <br>
                    • Virus Hepatitis B o Hepatitis C <br>
                    • Nódulos, masas quistes o tumores, pólipos y/ o lesiones de la piel. <br>
                    • Quimioterapia y/o radioterapia <br>

                  </label>

                  <br><br>

                  <v-col>
                    <v-radio-group v-model="userData.questionOne" :rules="questionRules" required row>
                      <div style="display: flex; margin: auto; padding-left: 15%; ">
                        <v-radio label="Si" value="Si" >
                        </v-radio>
                        <v-radio label="No" value="No" >
                        </v-radio>
                      </div>
                    </v-radio-group>
                  </v-col>

                </v-col>
              </div>

              <div class="button-container">
                <v-btn style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 300px; height: 120%;"
                  @click="AvanzarOptionOne()">
                  Continuar
                </v-btn>

                <br>
                <br>

                <v-btn text @click="volverAgregar()">
                  Atrás
                </v-btn>

              </div>

            </v-container>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="7">
          <v-form ref="form3" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>

                  <br v-if="screen > 800">
                  <br v-if="screen > 800">

                  <label class="labelQuestion1">
                    Pregunta 2
                  </label>

                  <br>
                  <br>

                  <label class="labelBeneficioss">
                    ¿Tú, {{ userData.name }} y/o alguno de tus asegurados adicionales <b> han tenido un resultado alterado
                      en cualquiera </b> de los siguientes exámenes?

                    <br>
                    <br>

                    • Virus del papiloma humano (VPH) <br>
                    • Antígeno prostático <br>
                    • Frontis de Papanicolau <br>
                    • Mamografía, ecografía, tomografía computarizada o resonancia magnética.

                  </label>

                  <br>
                  <br>

                  <v-col>
                    <v-radio-group v-model="userData.questionTwo" required :rules="questionRules" row>
                      <div style="display: flex; margin: auto; padding-left: 15%;">
                        <v-radio label="Si" value="Si">
                        </v-radio>
                        <v-radio label="No" value="No">
                        </v-radio>
                      </div>
                    </v-radio-group>
                  </v-col>

                  <br><br>

                </v-col>
              </div>

              <div class="button-container">
                <v-btn style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 300px; height: 120%;"
                  @click="AvanzarOptionTwo()">
                  Continuar
                </v-btn>

                <br>
                <br>

                <v-btn text @click="back()">
                  Atrás
                </v-btn>

              </div>

            </v-container>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="8">
          <v-form ref="form4" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>

                  <br v-if="screen > 800">
                  <br v-if="screen > 800">

                  <label class="labelQuestion1">
                    Pregunta 3
                  </label>

                  <br><br>

                  <label class="labelBeneficioss">
                    ¿Tú, {{ userData.name }} y/o alguno de tus asegurados adicionales <b> han sido rechazados </b> de un
                    seguro de vida o salud en el pasado?
                  </label>

                  <br><br>

                  <v-col>
                    <v-radio-group v-model="userData.questionThree" required :rules="questionRules" row>
                      <div style="display: flex; margin: auto; padding-left: 15%;">
                        <v-radio label="Si" value="Si">
                        </v-radio>
                        <v-radio label="No" value="No">
                        </v-radio>
                      </div>
                    </v-radio-group>
                  </v-col>

                  <br><br>

                </v-col>
              </div>

              <div class="button-container">
                <v-btn style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 300px; height: 120%;"
                  @click="AvanzarOptionThree()">
                  Continuar
                </v-btn>

                <br>
                <br>

                <v-btn text @click="back()">
                  Atrás
                </v-btn>

              </div>

            </v-container>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="9">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>

                  <br v-if="screen > 800">
                  <br v-if="screen > 800">

                  <br v-if="screen > 800">
                  <br v-if="screen > 800">

                  <h3 class="text-center labelExcelente">
                    ¡Excelente! <br> Podemos continuar con el proceso de proteger tu salud
                  </h3>

                  <br><br>

                  <h3 class="text-center labelSubExcelente">
                    Recuerda que si no declaras correctamente tu salud u omites información, podría afectar el cobro del
                    seguro si debes usarlo.
                  </h3>

                  <br>

                </v-col>
              </div>

              <div class="button-container">
                <v-btn style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 300px; height: 120%;"
                  @click="validate()">
                  Continuar
                </v-btn>

                <br>
                <br>

                <v-btn text @click="back()">
                  Atrás
                </v-btn>

              </div>

            </v-container>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="10">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">
                <v-col :class="vista">

                  <br>
                  <br>

                  <br v-if="screen > 800">
                  <br v-if="screen > 800">

                  <h3 class="text-center labelnoSubExcelente">
                    Debido a la condición de salud que respondiste, en este momento no podemos asegurar a tu grupo
                    familiar.
                    <br><br>Si alguno de tus familiares <b> no cumple </b> con los requisitos para estar asegurado, puedes
                    eliminarlo de tu carga familiar para continuar con tu contratación.
                  </h3>

                  <br v-if="screen < 800">

                  <div class="button-container">

                    <v-btn :color="stepperColor" style="color: white; border-radius: 12px; width: 200px; height: 95%;"
                      @click="volverAgregar()">
                      EDITAR FAMILIARES
                    </v-btn>

                    <br>
                    <br>
                    <br>
                    <br>

                    <h3 class="text-center labelNumLlamar">
                      Si tienes dudas o deseas contactarte con nosotros, por favor haz clic aquí.
                    </h3>

                    <v-btn
                      style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 300px; height: 120%;">
                      LLAMAR al 800 391 300
                    </v-btn>

                  </div>
                </v-col>
              </div>
            </v-container>
          </v-form>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>
  
<script>

import Titulo from "../components/Titulo.vue"
import NavBar from "@/components/NavBar.vue";

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    userData: {
      name: null,
      questionOne: null,
      questionTwo: null,
      questionThree: null
    },
    valid: false,
    stepperColor: '#00968F',
    screen: window.screen.width,
    stepId: null,
    components: { Titulo },
    e1: 6,
    questionRules: [(v) => !!v || "Debe seleccionar una opción"],
  }),
  mounted() {

    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.userData.name = localStorage.getItem("name");
    



    // this.stepId = localStorage.getItem('stepId')
    if (this.screen > 800) {
      this.vista = 'vista2'
    } else {
      this.vista = 'vista'
    }
  },
  methods: {
    AvanzarOptionOne() {
      if (this.$refs.form2.validate()) {
        if (this.userData.questionOne == 'Si') {
          this.e1 = 10;
        } else {
          localStorage.setItem("Q1", this.userData.questionOne);
          this.e1 += 1;
        }
      }
    },
    AvanzarOptionTwo() {
      if (this.$refs.form3.validate()) {
        if (this.userData.questionTwo == 'Si') {
          this.e1 = 10;
        } else {
          localStorage.setItem("Q2", this.userData.questionTwo);
          this.e1 += 1;
        }
      }
    },
    AvanzarOptionThree() {
      if (this.$refs.form4.validate()) {
        if (this.userData.questionThree == 'Si') {
          this.e1 = 10;
        } else {
          localStorage.setItem("Q3", this.userData.questionThree);
          this.e1 += 1;
        }
      }
    },
    back() {
      this.e1--;
    },
    validate() {
      var typePlan = localStorage.getItem("typePlan")
      if (typePlan == 'Individual') {
        this.$router.push({ path: '/datos-contratante'})
      } else {
        this.$router.push({ path: '/datos-beneficiarios'})
      }
    },
    volverAgregar() {
      this.$router.push({ path: '/beneficiarios'})
    }
  }
};

</script>
  
<style lang="scss">
.labelNumLlamar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 50px;
  top: 175px;
  text-align: center;
}

.labelSuperior {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: justify;
  color: #232323;
  opacity: 0.75;
  height: 103px;
}

.labelTitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
}

.labelQuestion1 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #002C77;
  opacity: 0.75;
  height: 138px;
  top: 375px;
  justify-content: left;
}

.labelTexto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 138px;
  text-align: justify;
}

.labelBeneficioss {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 150px;
  text-align: justify;
}

.labelExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  margin-top: -5%;
}

.labelSubExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 138px;
  top: 375px;
  text-align: center;
}

.labelNoExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  margin-top: -5%;
}

.labelnoSubExcelente {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  height: 138px;
  top: 375px;
  text-align: center;
}

.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-data {
  max-width: 85%;
  align-items: center;
  // display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 150px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

.form-box {
  box-sizing: border-box;
  max-width: 800px;
  background: #F3F3F3;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  border-radius: 6px;
}

.v-input .v-label {
  font-size: 12px;
  color: #8E8E8E;
}

h1 {
  font-weight: 500;
  text-align: center;
  color: #464e71;
  margin-bottom: 2rem;
}

h2 {
  font-weight: 500;
  color: #464e71;
  margin-bottom: 1rem;
}

p {
  text-align: center;
}

.back-container {
  width: 100%;
  align-items: flex-start;

  .back-button {


    &:hover {
      opacity: 1;
    }
  }
}

.button-container {
  text-align: center;
}

.forecast-selector {
  text-transform: uppercase;
  font-weight: 600;
  color: #464e71;
}

.v-btn__content {
  padding: 0.75rem;
}

.v-messages__message {
  padding-bottom: 10%;
  text-align: center;
  margin: auto;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div div.col.vista div.col div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message.message-transition-enter-to {
  padding-bottom: 10%;
  text-align: center;
  min-width: 100px;
}

.v-item--active > label:nth-child(2) {
  color:#002C77
}
</style>